import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const SSuzuTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "機械名", value: "machineCode" },
  { text: "作業者", value: "worker" },
  { text: "鈴引きサイズ(mm)", value: "tinSize" },
  { text: "抜き取り", value: "extraction" },
  { text: "仕上数20万m/147kg", value: "finish20km" },
  { text: "鈴引きサイズ(mm)", value: "tinSize2" },
  { text: "抜き取り", value: "extraction2" },
  { text: "仕上数10万m/149kg", value: "finish10km" },
  { text: "機械名", value: "machineCode2" },
  { text: "作業者", value: "worker2" },
  { text: "鈴引きサイズ(mm)", value: "tinSize3" },
  { text: "抜き取り", value: "extraction3" },
  { text: "仕上数5.5万m/80kg", value: "finish5p5km" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class SSuzuTable {
  workDate: string;
  updateTs: string | null;
  worker: string | null;
  machineCode: string | null;
  tinSize: number | null;
  extraction: number | null;
  finish20km: number | null;
  tinSize2: number | null;
  extraction2: number | null;
  finish10km: number | null;
  machineCode2: string | null;
  worker2: string | null;
  tinSize3: number | null;
  extraction3: number | null;
  finish5p5km: number | null;
  abnormality: string | null;
  biko: string | null;

  constructor(props: {
    workDate: string;
    worker: string | null;
    machineCode: string | null;
    tinSize: number | null;
    extraction: number | null;
    finish20km: number | null;
    tinSize2: number | null;
    extraction2: number | null;
    finish10km: number | null;
    machineCode2: string | null;
    worker2: string | null;
    tinSize3: number | null;
    extraction3: number | null;
    finish5p5km: number | null;
    abnormality: string | null;
    biko: string | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.worker = props.worker;
    this.machineCode = props.machineCode;
    this.tinSize = props.tinSize;
    this.extraction = props.extraction;
    this.finish20km = props.finish20km;
    this.tinSize2 = props.tinSize2;
    this.extraction2 = props.extraction2;
    this.finish10km = props.finish10km;
    this.machineCode2 = props.machineCode2;
    this.worker2 = props.worker2;
    this.tinSize3 = props.tinSize3;
    this.extraction3 = props.extraction3;
    this.finish5p5km = props.finish5p5km;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
  }
}

export const SSuzuText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  machineCode: { text: "機械名", value: "machineCode" },
  worker: { text: "作業者", value: "worker" },
  tinSize: { text: "鈴引きサイズ(mm)", value: "tinSize" },
  extraction: { text: "抜き取り", value: "extraction" },
  finish20km: { text: "仕上数20万m/147kg", value: "finish20km" },
  tinSize2: { text: "鈴引きサイズ(mm)", value: "tinSize2" },
  extraction2: { text: "抜き取り", value: "extraction2" },
  finish10km: { text: "仕上数10万m/149kg", value: "finish10km" },
  machineCode2: { text: "機械名", value: "machineCode2" },
  worker2: { text: "作業者", value: "worker2" },
  tinSize3: { text: "鈴引きサイズ(mm)", value: "tinSize3" },
  extraction3: { text: "抜き取り", value: "extraction3" },
  finish5p5km: { text: "仕上数5.5万m/80kg", value: "finish5p5km" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class SSuzu extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  worker: string | null;
  tinSize: number | null;
  extraction: number | null;
  finish20km: number | null;
  tinSize2: number | null;
  extraction2: number | null;
  finish10km: number | null;
  machineCode2: string | null;
  worker2: string | null;
  tinSize3: number | null;
  extraction3: number | null;
  finish5p5km: number | null;
  abnormality: string | null;
  biko: string | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: SSuzu);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: SSuzu | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof SSuzu) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.worker = _props.worker;
      this.tinSize = _props.tinSize;
      this.extraction = _props.extraction;
      this.finish20km = _props.finish20km;
      this.tinSize2 = _props.tinSize2;
      this.extraction2 = _props.extraction2;
      this.finish10km = _props.finish10km;
      this.machineCode2 = _props.machineCode2;
      this.worker2 = _props.worker2;
      this.tinSize3 = _props.tinSize3;
      this.extraction3 = _props.extraction3;
      this.finish5p5km = _props.finish5p5km;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "SSUZU";
    this.machineCode = null;
    this.worker = null;
    this.tinSize = 0.32;
    this.extraction = null;
    this.finish20km = null;
    this.tinSize2 = 0.45;
    this.extraction2 = null;
    this.finish10km = null;
    this.machineCode2 = null;
    this.worker2 = null;
    this.tinSize3 = 0.45;
    this.extraction3 = null;
    this.finish5p5km = null;
    this.abnormality = null;
    this.biko = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
